import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosFetch } from "../../Utils/AxiosFetch";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Booked from "../../assets/booked.png";
import Button from "@mui/material/Button";

const Player = ({}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const playerRef = useRef();
  const { state } = useLocation();

  const [totalDuration, setTotalDuration] = useState("");

  let { recording, meetingId, played_seconds } = state;

  const [progressSeconds, setProgressSeconds] = useState(0);
  const [resumeFromSeconds, setResumeFromSeconds] = useState(played_seconds);

  const handlePause = () => {
    console.log("pause");
  };

  const handleProgress = (progress) => {
    setProgressSeconds(progress.playedSeconds);
  };

  const onEnded = () => {
    setOpen(true);
  };

  const logPlayedSeconds = () => {
    axiosFetch({
      url: "/playProgramSessionVideo",
      method: "post",
      requestConfig: {
        meeting_id: meetingId !== null ? meetingId : undefined,
        played_seconds: progressSeconds !== null ? progressSeconds : undefined,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("duration logged");
        } else if (res.status === 400) {
          console.log("Bad request");
        } else if (res.status === 500) {
          console.log("Interal server error");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const markAsDone = () => {
    axiosFetch({
      method: "post",
      url: "/markProgramSessionAsWatched",
      requestConfig: {
        meeting_id: meetingId != null ? meetingId : undefined,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          navigate(-1);
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const seekTo = () => {
    playerRef.current.seekTo(resumeFromSeconds);
  };

  const MINUTE_MS = 15000;

  // const handleFullscreen = () => {
  //   if (document.fullscreenEnabled) {
  //     const playerElement = playerRef.current.wrapper;
  //     if (!document.fullscreenElement) {
  //       playerElement.requestFullscreen();
  //     } else {
  //       document.exitFullscreen();
  //     }
  //   }
  // };

  const handleFullscreen = () => {
    if (document.fullscreenEnabled) {
      const playerElement = playerRef.current.wrapper;
      if (!document.fullscreenElement) {
        playerElement.requestFullscreen().catch((err) => {
          console.error(`Failed to enter fullscreen mode: ${err.message}`);
        });
      } else {
        document.exitFullscreen().catch((err) => {
          console.error(`Failed to exit fullscreen mode: ${err.message}`);
        });
      }
    }
  };

  useEffect(() => {
    if (progressSeconds !== 0) {
      const interval = setInterval(() => {
        logPlayedSeconds();
      }, MINUTE_MS);
      return () => clearInterval(interval);
    }
  }, []);
  return (
    <>
      <div>
        <IconButton aria-label="arrow" onClick={() => navigate(-1)}>
          <ArrowBackIosIcon />
        </IconButton>
      </div>
      <video
        src={recording}
        width="100%"
        height="100%"
        controls
        controlsList="nodownload noplaybackrate"
        disablePictureInPicture
        autoplay
        onEnded={onEnded}
      />

      {/* <ReactPlayer
        ref={playerRef}
        url={recording}
        // url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        width="100%"
        height="100%"
        controls={true}
        onPause={logPlayedSeconds}
        onProgress={(progress) => handleProgress(progress)}
        onEnded={onEnded}
        onError={() => console.log("err")}
        onDuration={(duration) => setTotalDuration(duration)}
        onStart={seekTo}
        config={{
          playerOptions: {
            playsinline: true,
          },
        }}
      /> */}
      {/* <button onClick={handleFullscreen}>Fullscreen</button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={Booked}
            style={{
              margin: "auto",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              transform: "translate(108px, 10px)",
            }}
          />
          <br />
          <br />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "black",
              color: "#fff",
              textTransform: "Capitalize",
              justifyContent: "center",
              alignItems: "center",
              transform: "translate(128px, 10px)",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
            onClick={() => markAsDone()}
          >
            Mark as done
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Player;
